import React from 'react';
import '../styles/App.scss'


function navigation() {
    return (
        <div className = "main-nav">
            <header className = "nav-logo" data-text="chonks.">chonks.</header>
        </div>
    )
}

export default navigation;