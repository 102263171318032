import React, { Component } from "react";
 
class PageNotFound extends Component {
  render() {
    return (
      <div>
        <h2>404</h2>
      </div>
    );
  }
}
 
export default PageNotFound;