import React, { Component } from "react";
import Navigation from './navigation/navigation'
 
class Home extends Component {
  render() {
    return (
      <div>
          <Navigation></Navigation>
        <h2>Le epic chonks</h2>
      </div>
    );
  }
}
 
export default Home;